export var BRANCHES = {
    1: 'brest',
    2: 'east',
    3: 'northwest',
    4: 'school'
};
export var PLAYERS_POSITIONS = [
    {
        value: 'Вратарь',
        title: 'Вратарь'
    },
    {
        value: 'Защитник',
        title: 'Защитник'
    },
    {
        value: 'Полузащитник',
        title: 'Полузащитник'
    },
    {
        value: 'Нападающий',
        title: 'Нападающий'
    }
];
export var STAFF_POSITIONS = [
    {
        value: 'Главный тренер',
        title: 'Главный тренер'
    },
    {
        value: 'Тренер',
        title: 'Тренер'
    },
    {
        value: 'Тренер по технике бега',
        title: 'Тренер по технике бега'
    },
    {
        value: 'Ассистент',
        title: 'Ассистент'
    },
    {
        value: 'Тренер по физической подготовке',
        title: 'Тренер по физической подготовке'
    },
    {
        value: 'Тренер по вратарям',
        title: 'Тренер по вратарям'
    },
    {
        value: 'Врач',
        title: 'Врач'
    },
    {
        value: 'Администратор',
        title: 'Администратор'
    }
];
export var TOURNAMENT_SEASONS = [
    {
        id: 1,
        title: '22/23',
        value: '22/23'
    },
    {
        id: 2,
        title: '23',
        value: '23'
    },
    {
        id: 3,
        title: '23/24',
        value: '23/24'
    },
    {
        id: 4,
        title: '24',
        value: '24'
    },
    {
        id: 5,
        title: '24/25',
        value: '24/25'
    },
    {
        id: 6,
        title: '25',
        value: '25'
    },
    {
        id: 7,
        title: '25/26',
        value: '25/26'
    },
    {
        id: 8,
        title: '26',
        value: '26'
    }
];
