var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAccount } from '@store/account';
import { ButtonComponent, InputComponent } from '@components/common';
import { userApi } from '@api';
import { emailValidator } from '@utils/validators';
import { showNotification } from '@utils/notification';
import { AUTHTOKEN } from '@utils/constants';
import './index.scss';
export var LoginPage = function () {
    var state = useLocation().state;
    var history = useHistory();
    var dispatch = useDispatch();
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(), emailError = _c[0], setEmailError = _c[1];
    var from = (state || {}).from;
    var onValidateEmail = function (e) {
        // const {value} = e.target;
        var error = emailValidator(email);
        if (error) {
            setEmailError(error);
        }
        else {
            setEmailError(null);
        }
    };
    var onChangeEmail = function (e) {
        var value = e.target.value;
        setEmail(value);
    };
    var onChangePassword = function (e) {
        var value = e.target.value;
        setPassword(value);
    };
    var handleLogin = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var obj, res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    obj = {
                        email: email.toLowerCase(),
                        password: password.toLowerCase(),
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, userApi.login(obj)];
                case 2:
                    res = _a.sent();
                    localStorage.setItem(AUTHTOKEN, res.data.token);
                    dispatch(setAccount(res.data));
                    history.replace(from || '/');
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    showNotification('error', err_1);
                    return [3 /*break*/, 5];
                case 4: return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "login-page" },
        React.createElement("div", null,
            React.createElement("div", null, "Login"),
            React.createElement("form", { onSubmit: handleLogin },
                React.createElement("div", null,
                    React.createElement(InputComponent, { value: email, onBlur: onValidateEmail, onChange: onChangeEmail }),
                    React.createElement("p", null, emailError)),
                React.createElement("div", null,
                    React.createElement(InputComponent, { value: password, onChange: onChangePassword })),
                React.createElement(ButtonComponent, { className: "login-page-form-submit", type: "submit" }, "login")))));
};
