// @ts-nocheck
import React from 'react';
import './styles/index.scss';
export var SelectComponent = function (_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, label = _a.label, _b = _a.options, options = _b === void 0 ? [] : _b, className = _a.className;
    var selectClassName = "select-component " + (className || '');
    return (React.createElement("div", { className: selectClassName },
        React.createElement("label", { htmlFor: "" }, label),
        React.createElement("select", { name: name, value: value, onChange: onChange },
            React.createElement("option", { value: "" }),
            options.map(function (option) { return (React.createElement("option", { value: option.value }, option.title)); }))));
};
