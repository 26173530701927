var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { InputComponent, SelectComponent } from '@components/common';
import { adminApi } from '@api';
import { showNotification } from '@utils/notification';
import { modifyDate } from '@utils/modified';
import './styles/index.scss';
export var AdminTournamentMatchComponent = function (props) {
    var matchData = props.matchData, teams = props.teams, tournament = props.tournament, round = props.round, stadiums = props.stadiums, isCustomMatch = props.isCustomMatch, _a = props.withTitle, withTitle = _a === void 0 ? true : _a, onAddNewMatch = props.onAddNewMatch;
    var _b = useState(matchData || {}), match = _b[0], setMatch = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var onChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setMatch(__assign(__assign({}, match), (_a = {}, _a[name] = value, _a)));
    };
    var onChangeHidden = function (e) {
        var checked = e.target.checked;
        setMatch(__assign(__assign({}, match), { isHidden: checked }));
    };
    var isValid = function (m) {
        if (!m.homeTeamId || !m.guestTeamId)
            return false;
        return true;
    };
    var onSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    if (!isValid(match))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, adminApi.createTournamentMatch(__assign(__assign({}, match), { tournament: tournament, round: round, isCustomMatch: isCustomMatch }))];
                case 1:
                    res = _a.sent();
                    showNotification('success', 'Матч успешно добавлен');
                    setMatch({
                        title: '',
                        note: '',
                        date: '',
                        time: '',
                        homeTeamId: '',
                        guestTeamId: '',
                        homeGoals: '',
                        guestGoals: '',
                        stadium: ''
                    });
                    onAddNewMatch(res.data);
                    return [3 /*break*/, 4];
                case 2:
                    err_1 = _a.sent();
                    showNotification('error', err_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [match, tournament, round]);
    var onUpdate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    if (!isValid(match))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, adminApi.updateTournamentMatch(match.id, match)];
                case 1:
                    _a.sent();
                    showNotification('success', 'Матч успешно обновлен');
                    return [3 /*break*/, 4];
                case 2:
                    err_2 = _a.sent();
                    showNotification('error', err_2);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [match]);
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, adminApi.deleteTournamentMatch(match.id)];
                case 1:
                    _a.sent();
                    showNotification('success', 'Матч успешно удален');
                    return [3 /*break*/, 4];
                case 2:
                    err_3 = _a.sent();
                    showNotification('error', err_3);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("tr", null,
        React.createElement("td", null,
            React.createElement("input", { type: "checkbox", name: "isHidden", checked: match.isHidden, onChange: onChangeHidden })),
        withTitle && (React.createElement("td", null,
            React.createElement(InputComponent, { name: "title", className: "admin-tournament-matches-component-title", value: match.title, onChange: onChange }))),
        React.createElement("td", null,
            React.createElement(InputComponent, { name: "date", className: "admin-tournament-matches-component-date", type: "date", value: modifyDate(match.date, true), onChange: onChange })),
        React.createElement("td", null,
            React.createElement(InputComponent, { name: "time", className: "admin-tournament-matches-component-time", type: "time", value: match.time, onChange: onChange })),
        React.createElement("td", null,
            React.createElement(SelectComponent, { name: "stadium", className: "admin-tournament-matches-component-home", value: match.stadium, onChange: onChange, options: stadiums })),
        React.createElement("td", null,
            React.createElement(SelectComponent, { name: "homeTeamId", className: "admin-tournament-matches-component-home", value: match.homeTeamId, onChange: onChange, options: teams })),
        React.createElement("td", null,
            React.createElement(SelectComponent, { name: "guestTeamId", className: "admin-tournament-matches-component-guest", value: match.guestTeamId, onChange: onChange, options: teams })),
        React.createElement("td", null,
            React.createElement("div", { className: "admin-tournament-matches-component-result" },
                React.createElement(InputComponent, { name: "homeGoals", className: "admin-tournament-matches-component-home-goal", value: match.homeGoals, onChange: onChange }),
                React.createElement("span", { className: "admin-tournament-matches-component-result-separator" }, ":"),
                React.createElement(InputComponent, { name: "guestGoals", className: "admin-tournament-matches-component-guest-goal", value: match.guestGoals, onChange: onChange }))),
        withTitle && (React.createElement("td", null,
            React.createElement(InputComponent, { name: "note", className: "admin-tournament-matches-component-title", value: match.note, onChange: onChange }))),
        React.createElement("td", { className: "admin-tournament-matches-component-actions" },
            React.createElement("span", { onClick: match.id ? onUpdate : onSave }, match.id ? 'Сохр' : 'Добавить'),
            match.id
                && React.createElement(React.Fragment, null,
                    ' / ',
                    React.createElement("span", { onClick: onDelete }, "\u0423\u0434\u0430\u043B")))));
};
