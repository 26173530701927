import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/index.scss';
export var AdminSidebarComponent = function () {
    var location = useLocation();
    return (React.createElement("div", { className: "admin-sidebar-component" },
        React.createElement("div", { className: "admin-sidebar-component-title" }, "Admin panel"),
        React.createElement("ul", { className: "admin-sidebar-component-nav" },
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin' && 'active'), to: "/admin" }, "Dashboard")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/post-list' && 'active'), to: "/admin/post-list" }, "\u041D\u043E\u0432\u043E\u0441\u0442\u0438")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/branch-list' && 'active'), to: "/admin/branch-list" }, "\u0424\u0438\u043B\u0438\u0430\u043B\u044B")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/team-list' && 'active'), to: "/admin/team-list" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u044B")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/player-list' && 'active'), to: "/admin/player-list" }, "\u0418\u0433\u0440\u043E\u043A\u0438")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/staff-list' && 'active'), to: "/admin/staff-list" }, "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/application-list' && 'active'), to: "/admin/application-list" }, "\u0417\u0430\u044F\u0432\u043A\u0438")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/tournaments-list' && 'active'), to: "/admin/tournaments-list" }, "\u0422\u0443\u0440\u043D\u0438\u0440\u044B")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/tournament/teams-list' && 'active'), to: "/admin/tournament/teams-list" }, "\u0422\u0443\u0440\u043D\u0438\u0440\u043D\u044B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u044B")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/tournament/custom-matches' && 'active'), to: "/admin/tournament/custom-matches" }, "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0435 \u043C\u0430\u0442\u0447\u0438")),
            React.createElement("li", { className: "admin-sidebar-component-nav-item" },
                React.createElement(Link, { className: "admin-sidebar-component-nav-item-link " + ((location === null || location === void 0 ? void 0 : location.pathname) === '/admin/stadiums' && 'active'), to: "/admin/stadiums" }, "\u0421\u0442\u0430\u0434\u0438\u043E\u043D\u044B")))));
};
