// @ts-nocheck
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export var showNotification = function (type, msg) {
    if (type === void 0) { type = 'info'; }
    var text = '';
    var properties = {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        newestOnTop: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    switch (type) {
        case 'error':
            properties.autoClose = 5000;
            if (msg.response instanceof Object
                && msg.response.data instanceof Object
                && msg.response.data.error instanceof Object
                && msg.response.data.error.message) {
                text = msg.response.data.error.message;
            }
            else if (msg instanceof Object
                && msg.response instanceof Object
                && msg.response.data instanceof Object
                && (typeof msg.response.data.error === 'string'
                    || typeof msg.response.data.message === 'string')) {
                text = msg.response.data.message || msg.response.data.error;
            }
            else if (msg instanceof Object
                && (typeof msg.message === 'string' || typeof msg.error === 'string')) {
                text = msg.message || msg.error;
            }
            else if (typeof msg === 'string') {
                text = msg;
            }
            else {
                text = 'error';
            }
            break;
        case 'info':
            properties.autoClose = 5000;
            if (msg instanceof String || typeof msg === 'string')
                text = msg;
            break;
        case 'success':
            if (msg instanceof String || typeof msg === 'string')
                text = msg;
            break;
        default:
    }
    toast[type](text, properties);
};
