// @ts-nocheck
import React from 'react';
import './styles/index.scss';
export var TextareaComponent = function (_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, label = _a.label, className = _a.className;
    var inputClassName = "textarea-component " + (className || '');
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: "" }, label),
        React.createElement("textarea", { className: inputClassName, name: name, value: value, onChange: onChange })));
};
