import axios from 'axios';
import { AUTHTOKEN } from '@utils/constants/variables';
// import { store } from 'lib/store/store'
// import { authActions } from 'features/auth'
// import { apiLogin } from 'api/account'
// import { setSession } from 'features/auth'
// import { showNotification } from 'lib/notification'

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(AUTHTOKEN);

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
        }

        return config;
    },
    (err) => Promise.reject(err)
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const {
            config,
            response: { status },
        } = error;
        const originalRequest = config;

        if (status === 401) {
            // store.dispatch(authActions.logout(true))
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);
