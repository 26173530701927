// @ts-nocheck
import axios from 'axios';
export var getPostList = function (skip, limit) {
    if (skip === void 0) { skip = 0; }
    if (limit === void 0) { limit = 18; }
    return axios.get("/api/post/list?skip=" + skip + "&limit=" + limit);
};
export var getPostById = function (id) { return axios.get("/api/post/" + id); };
export var updatePost = function (id, data) { return axios.put("/api/post/" + id, data); };
export var deletePost = function (id, data) { return axios.delete("/api/post/" + id); };
export var postApi = {
    getPostList: getPostList,
    getPostById: getPostById,
    updatePost: updatePost,
    deletePost: deletePost
};
