var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// @ts-nocheck
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
export var AdminProtectedRoute = function (props) {
    var Component = props.component, rest = __rest(props, ["component"]);
    var _a = useSelector(function (state) { return state.account; }), isAuth = _a.isAuth, isLoaded = _a.isLoaded;
    // Wait till end of fetching profile
    if (!isLoaded)
        return null;
    if (!isAuth) {
        return (React.createElement(Redirect, { to: {
                pathname: '/login',
                state: { from: props.location }
            } }));
    }
    return (React.createElement(Route, __assign({}, rest, { component: Component })));
};
