// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { fetchBranchList, fetchStaff } from './branchThunk';
export var branchSlice = createSlice({
    name: 'branch',
    initialState: {
        loading: false,
        loadingStaff: false,
        list: [],
        staff: []
    },
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(fetchBranchList.pending, function (state, action) {
            state.loading = true;
        });
        builder.addCase(fetchBranchList.fulfilled, function (state, action) {
            // Add user to the state array
            state.list = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchStaff.pending, function (state, action) {
            state.loadingStaff = true;
        });
        builder.addCase(fetchStaff.fulfilled, function (state, action) {
            state.staff = action.payload;
            state.loadingStaff = false;
        });
    },
});
// export const {} = branchSlice.actions;
export default branchSlice.reducer;
