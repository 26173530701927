var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { branchApi } from '@api';
import { ButtonComponent, InputComponent } from '@components/common';
import { showNotification } from '@utils/notification';
export var AdminBranchPage = function () {
    var id = useParams().id;
    var _a = useState(null), branch = _a[0], setBranch = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var getBranch = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, branchApi.getById(id)];
                case 1:
                    res = _a.sent();
                    setBranch(res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log('e', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!id)
            return;
        getBranch(id);
    }, [id]);
    var handleUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(branch === null || branch === void 0 ? void 0 : branch.id))
                        return [2 /*return*/];
                    setLoading(true);
                    data = {
                        title: branch.title,
                        description: branch.description
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, branchApi.update(branch === null || branch === void 0 ? void 0 : branch.id, data)];
                case 2:
                    res = _a.sent();
                    showNotification('success', 'Филиал успешно обновлен');
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        setBranch(__assign(__assign({}, branch), (_a = {}, _a[name] = value, _a)));
    };
    if (!branch)
        return null;
    return (React.createElement("div", { className: "admin-page-branch" },
        React.createElement("div", { className: "admin-page-title" }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u0444\u0438\u043B\u0438\u0430\u043B\u0430"),
        React.createElement("div", null,
            React.createElement("div", { className: "admin-form-block" },
                React.createElement(InputComponent, { name: "title", value: branch.title, onChange: onChange, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })),
            React.createElement("div", { className: "admin-form-handler" },
                React.createElement(ButtonComponent, { className: "admin-post-component-handler-button", onClick: handleUpdate, loading: loading }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")),
            React.createElement("div", { className: "admin-page-second-block" },
                React.createElement("div", { className: "admin-page-subtitle" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u044B"),
                React.createElement("table", { className: "admin-table" },
                    React.createElement("tr", null,
                        React.createElement("th", null, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A"),
                        React.createElement("th", null, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
                    branch.teams.map(function (team) { return (React.createElement("tr", { key: team.id },
                        React.createElement("td", null, team.title),
                        React.createElement("td", null,
                            React.createElement(Link, { to: "/admin/teams/" + team.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); }))))));
};
