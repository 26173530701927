// @ts-nocheck
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProfile, setAccountLoaded } from '@store/account';
import { AUTHTOKEN } from '@utils/constants';
export var AccountLoaderComponent = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var loadSession = function () {
        var TOKEN = localStorage.getItem(AUTHTOKEN);
        if (TOKEN) {
            dispatch(fetchProfile());
        }
        else {
            dispatch(setAccountLoaded());
        }
    };
    useEffect(function () {
        loadSession();
    }, []);
    // if (TOKEN && !isAuth) return <div>Loading...</div>
    return children;
};
