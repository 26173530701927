// @ts-nocheck
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { HeaderComponent } from '@components/common';
import { AccountLoaderComponent } from '@components/account';
import { fetchBranchList } from '@store/branch';
import { Routes } from './pages';
import '@utils/axiosInterceptors';
export var App = function () {
    var location = useLocation();
    var dispatch = useDispatch();
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [location]);
    useEffect(function () {
        dispatch(fetchBranchList());
    }, []);
    var isAdminLocation = location.pathname.includes('/admin');
    return (React.createElement(React.Fragment, null,
        React.createElement(AccountLoaderComponent, null,
            !isAdminLocation && React.createElement(HeaderComponent, null),
            React.createElement(Routes, null),
            React.createElement(ToastContainer, null),
            React.createElement("div", { id: "modal" }))));
};
