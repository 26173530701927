// @ts-nocheck
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { configureStore } from '@utils/store';
import store from '@store/index';
import { App } from './app';
import './styles/index.scss';
// const store = configureStore(window.__PRELOADED_STATE__);
hydrate(React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, null,
        React.createElement(App, null))), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
