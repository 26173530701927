var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { adminApi } from '@api';
import { ButtonComponent, FileUploadComponent, InputComponent } from '@components/common';
import './styles/index.scss';
export var AdminGallery = function (props) {
    var onChooseImage = props.onChooseImage;
    var _a = useState([]), gallery = _a[0], setGallery = _a[1];
    var _b = useState(false), isLoadingGallery = _b[0], setIsLoadingGallery = _b[1];
    var _c = useState(false), isLoadingFile = _c[0], setIsLoadingFile = _c[1];
    var _d = useState(null), file = _d[0], setFile = _d[1];
    var _e = useState(''), selected = _e[0], setSelected = _e[1];
    var fetchGallery = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoadingGallery(true);
                    return [4 /*yield*/, adminApi.getGallery()];
                case 1:
                    res = _a.sent();
                    setGallery(res.data);
                    setIsLoadingGallery(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchGallery();
    }, []);
    var onFileChange = function (file) {
        setFile(file);
    };
    var onUpload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formData, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formData = new FormData();
                    formData.append('file', file, file.name);
                    setIsLoadingFile(true);
                    return [4 /*yield*/, adminApi.uploadImage(formData)];
                case 1:
                    res = _a.sent();
                    setGallery(__spreadArrays([res.data], gallery));
                    setIsLoadingFile(false);
                    // await fetchGallery();
                    setFile(null);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "admin-gallery" },
        React.createElement("div", { className: "admin-gallery-new" },
            React.createElement(FileUploadComponent, { onFileChange: onFileChange }),
            React.createElement("div", { className: "admin-gallery-new-preview" }, file && (React.createElement("img", { src: URL.createObjectURL(file), alt: "" }))),
            file && React.createElement(ButtonComponent, { onClick: onUpload, loading: isLoadingFile }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0432 \u0433\u0430\u043B\u0435\u0440\u0435\u044E")),
        React.createElement("div", { className: "admin-gallery-box" }, isLoadingGallery ? (React.createElement("div", null, "Loading...")) : (React.createElement(React.Fragment, null, gallery.map(function (image) { return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        React.createElement("img", { className: "admin-gallery-box-item " + (selected === image.url && 'selected'), alt: "", src: "/" + image.url, width: 100, onClick: function () { return setSelected(image.url); }, loading: "lazy" })); })))),
        React.createElement("div", { className: "admin-gallery-action-wrapper" },
            React.createElement(InputComponent, { className: "admin-gallery-action-input", value: window.location.origin + "/" + selected }),
            React.createElement(ButtonComponent, { onClick: function () { return onChooseImage(selected); }, disabled: !selected }, "\u0412\u044B\u0431\u0440\u0430\u0442\u044C"))));
};
