var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { adminApi } from '@api';
import { Loader } from '@components/common';
import { AdminStadium } from '@components/admin';
export var AdminStadiumsPage = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), stadiums = _b[0], setStadiums = _b[1];
    useEffect(function () {
        var func = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setLoading(true);
                        return [4 /*yield*/, adminApi.getStadiums()];
                    case 1:
                        res = _a.sent();
                        setStadiums(res.data);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        func();
    }, []);
    var onAddNewStadium = function (m) {
        var copy = __spreadArrays(stadiums);
        setStadiums(__spreadArrays([m], copy));
    };
    var onDeleteStadium = function (id) {
        var fltrs = stadiums.filter(function (s) { return s.id !== id; });
        setStadiums(fltrs);
    };
    if (loading)
        return React.createElement(Loader, null);
    return (React.createElement("div", null,
        React.createElement("div", { className: "admin-page-title" }, "\u0421\u0442\u0430\u0434\u0438\u043E\u043D\u044B"),
        React.createElement("div", { className: "" },
            React.createElement("table", { className: "admin-table" },
                React.createElement("tr", null,
                    React.createElement("th", null, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435"),
                    React.createElement("th", null, "\u0410\u0434\u0440\u0435\u0441"),
                    React.createElement("th", null)),
                React.createElement(AdminStadium, { onAddNewStadium: onAddNewStadium }),
                stadiums.map(function (stadium) { return (React.createElement(AdminStadium, { key: stadium.id, stadiumData: stadium, onDeleteStadium: onDeleteStadium })); })))));
};
