// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import logoNew from '@static/img/icons/logo-new.svg';
import './index.scss';
export var HeaderComponent = function () {
    return (React.createElement("div", { className: "header-component" },
        React.createElement(Link, { to: "/" },
            React.createElement("img", { className: "header-component-logo-img", 
                // src={isTransparent ? logo : logoColor}
                src: logoNew, alt: "logo" }))));
};
