var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { PATH_TO_FILE } from '@utils/constants';
import { ButtonComponent, InputComponent, ModalComponent, SelectComponent, TextareaComponent } from '@components/common';
import { AdminGallery } from '@components/admin';
import { adminApi } from '@api';
import { TOURNAMENT_SEASONS } from '@common';
import './styles/index.scss';
var getRounds = function () {
    var N = 30;
    return Array.from({ length: N }, function (_, index) { return index + 1; });
};
var ARR = getRounds();
var ROUNDS = ARR.map(function (item) { return ({ value: item, title: item }); });
export var AdminTournamentComponent = function (props) {
    var _a = props.tournamentData, tournamentData = _a === void 0 ? {} : _a, onEvent = props.onEvent, onDelete = props.onDelete, loading = props.loading;
    var _b = useState(tournamentData), tournament = _b[0], setTournament = _b[1];
    var _c = useState(false), isOpenGallery = _c[0], setIsOpenGallery = _c[1];
    var _d = useState(''), imageUrl = _d[0], setImageUrl = _d[1];
    var _e = useState([]), teams = _e[0], setTeams = _e[1];
    var _f = useState([]), selectedTeams = _f[0], setSelectedTeams = _f[1];
    var _g = useState(false), isLoadingTeams = _g[0], setIsLoadingTeams = _g[1];
    var _h = useState(false), isLoaded = _h[0], setIslLoaded = _h[1];
    useEffect(function () {
        if (!(tournamentData === null || tournamentData === void 0 ? void 0 : tournamentData.id))
            return;
        setImageUrl(tournamentData === null || tournamentData === void 0 ? void 0 : tournamentData.imageUrl);
    }, [tournamentData]);
    // getting list of tournament teams
    useEffect(function () {
        var getTournamentTeams = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setIsLoadingTeams(true);
                        return [4 /*yield*/, adminApi.getTournamentAndOurTeams()];
                    case 1:
                        res = _a.sent();
                        setTeams(res.data);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        setIsLoadingTeams(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        getTournamentTeams();
    }, []);
    // preset teams that present in current tournament
    useEffect(function () {
        var _a;
        if (!((_a = tournament === null || tournament === void 0 ? void 0 : tournament.teams) === null || _a === void 0 ? void 0 : _a.length) || !(teams === null || teams === void 0 ? void 0 : teams.length) || isLoaded)
            return;
        var ids = tournament.teams.map(function (i) { return i.id; });
        setSelectedTeams(ids);
        var mapped = teams.map(function (team) {
            var found = tournament.teamsPostfixes.find(function (psfx) { return psfx.id === team.id; });
            if (found) {
                return __assign(__assign({}, team), { postfix: found.postfix });
            }
            return team;
        });
        setTeams(mapped);
        setIslLoaded(true);
    }, [tournament === null || tournament === void 0 ? void 0 : tournament.teams, teams, isLoaded]);
    var handleEvent = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var postfixes, filtered, obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    postfixes = teams.map(function (team) { return ({
                        id: team.id,
                        postfix: team.postfix
                    }); });
                    filtered = postfixes.filter(function (p) { return selectedTeams.includes(p.id); });
                    obj = __assign({}, tournament);
                    obj.imageUrl = imageUrl;
                    obj.teams = selectedTeams;
                    obj.teamsPostfixes = filtered;
                    return [4 /*yield*/, onEvent(obj)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [imageUrl, selectedTeams, teams, tournament]);
    var onChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        setTournament(__assign(__assign({}, tournament), (_a = {}, _a[name] = value, _a)));
    };
    var onChangeCup = function (e) {
        var checked = e.target.checked;
        setTournament(__assign(__assign({}, tournament), { isCup: checked }));
    };
    var onChangeMain = function (e) {
        var checked = e.target.checked;
        setTournament(__assign(__assign({}, tournament), { isMain: checked }));
    };
    var closeModal = function () {
        setIsOpenGallery(false);
    };
    var onChooseImage = function (url) {
        setImageUrl(url);
        closeModal();
    };
    var onTeamCheckbox = function (e, teamId) {
        var copy = __spreadArrays(selectedTeams);
        if (copy.includes(teamId)) {
            copy = copy.filter(function (id) { return id !== teamId; });
        }
        else {
            copy.push(teamId);
        }
        setSelectedTeams(copy);
    };
    var onChangeTeam = useCallback(function (e, id) {
        var value = e.target.value;
        var newTeams = teams.map(function (t) {
            if (t.id === id) {
                return __assign(__assign({}, t), { postfix: value });
            }
            return t;
        });
        setTeams(newTeams);
    }, [teams]);
    var disabled = !tournament.title;
    return (React.createElement("div", { className: "admin-tournament-component" },
        React.createElement("div", { className: "admin-post-component-content" },
            React.createElement("div", { className: "admin-post-component-left" },
                React.createElement("div", { className: "admin-post-component-image " + (imageUrl && 'has-image') },
                    React.createElement("img", { src: PATH_TO_FILE + "/" + imageUrl, alt: "" })),
                React.createElement(ButtonComponent, { variant: "outline", onClick: function () { return setIsOpenGallery(true); } }, imageUrl ? 'Выбрать другое' : 'Добавить изображение')),
            React.createElement("div", { className: "admin-post-component-right" },
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(InputComponent, { name: "title", value: tournament.title, onChange: onChange, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })),
                React.createElement("div", { className: "admin-tournament-component-cup" },
                    React.createElement("input", { type: "checkbox", id: "cup", checked: tournament.isCup, onChange: onChangeCup }),
                    React.createElement("label", { htmlFor: "cup" }, "\u042D\u0442\u043E \u043A\u0443\u0431\u043E\u043A")),
                React.createElement("div", { className: "admin-tournament-component-cup" },
                    React.createElement("input", { type: "checkbox", id: "is-main", checked: tournament.isMain, onChange: onChangeMain }),
                    React.createElement("label", { htmlFor: "is-main" }, "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0442\u044C \u044D\u0442\u043E\u0442 \u0442\u0443\u0440\u043D\u0438\u0440 \u043A\u0430\u043A \u0433\u043B\u0430\u0432\u043D\u044B\u0439 \u0432 \u0441\u0435\u0437\u043E\u043D\u0435")),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(TextareaComponent, { name: "description", value: tournament.description, onChange: onChange, label: "\u041A\u0440\u0430\u0442\u043A\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F (\u043D\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E)" })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement("div", null, "\u041A\u043E\u043C\u0430\u043D\u0434\u044B:"),
                    React.createElement("div", { className: "admin-tournament-component-teams" },
                        isLoadingTeams && React.createElement("span", null, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430..."),
                        teams.map(function (team) { return (React.createElement("div", { className: "admin-form-block-checkbox-block " + (selectedTeams.includes(team.id) && 'active') },
                            React.createElement("input", { type: "checkbox", id: "check-" + team.id, checked: selectedTeams.includes(team.id), onChange: function (e) { return onTeamCheckbox(e, team.id); } }),
                            React.createElement("label", { htmlFor: "check-" + team.id }, team.title),
                            React.createElement(InputComponent, { name: "title", value: team.postfix, onChange: function (e) { return onChangeTeam(e, team.id); }, placeholder: "\u041F\u043E\u0441\u0442\u0444\u0438\u043A\u0441" }))); }))),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(SelectComponent, { name: "season", label: "\u0421\u0435\u0437\u043E\u043D", value: tournament.season, onChange: onChange, options: TOURNAMENT_SEASONS })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(SelectComponent, { name: "rounds", label: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0442\u0443\u0440\u043E\u0432", value: tournament.rounds, onChange: onChange, options: ROUNDS })),
                React.createElement("div", { className: "admin-post-component-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button", onClick: handleEvent, disabled: disabled, loading: loading }, tournament.id ? 'Редактировать' : 'Создать')),
                onDelete && (React.createElement("div", { className: "admin-post-component-handler admin-post-component-delete-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button admin-post-component-delete-handler-button", onClick: onDelete, disabled: disabled, loading: loading }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))))),
        React.createElement(ModalComponent, { isOpen: isOpenGallery, closeModal: closeModal },
            React.createElement(AdminGallery, { onChooseImage: onChooseImage }))));
};
