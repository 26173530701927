// @ts-nocheck
import axios from 'axios';
// import { API_URL } from 'lib/CONST'
export var login = function (data) { return axios.post('/api/users/login', data); };
export var getProfile = function () { return axios.get('/api/users/profile'); };
// get list users by subcategory
export var getUsers = function (subcategory) { return axios.get("/api/users/list?subcategory=" + subcategory); };
// create new use
export var createNew = function (data) { return axios.post('/api/users/new', data); };
// get user by id
export var getUser = function (id) { return axios.get("/api/users/" + id); };
export var getAccount = function (id) { return axios.get("/api/users/" + id); };
export var sendRequest = function (data) { return axios.post('/api/users/send-request', data); };
// export const register = (data: object) => axios.post('/api/users/register', data);
// export const getAccount = () => axios.get('/api/users/profile');
// export const confirmMail = (token) => axios.post('/api/users/confirmMail', {token});
// export const edit = (data: object) => axios.put('/api/users/profile', data);
// export const uploadAvatar = (data: object) => axios.post('/api/users/profile/avatar', data);
// export const uploadPhotos = (data: object) => axios.post('/api/users/profile/photos', data);
export var userApi = {
    login: login,
    getProfile: getProfile,
    getUsers: getUsers,
    getUser: getUser,
    createNew: createNew,
    sendRequest: sendRequest
};
