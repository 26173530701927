var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { branchApi } from '@api';
import { Link, useParams } from 'react-router-dom';
import { teamApi } from '@api';
import { InputComponent } from '@components/common';
export var AdminTeamPage = function () {
    var _a;
    var id = useParams().id;
    var _b = useState(null), team = _b[0], setTeam = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var getTeam = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, teamApi.getById(id)];
                case 1:
                    res = _a.sent();
                    setTeam(res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log('e', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!id)
            return;
        getTeam(id);
    }, [id]);
    var onChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        setTeam(__assign(__assign({}, team), (_a = {}, _a[name] = value, _a)));
    };
    var handleUpdate = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var data, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!(team === null || team === void 0 ? void 0 : team.id))
                        return [2 /*return*/];
                    data = {
                        title: team.title,
                        description: team.description,
                        content: team.content,
                    };
                    return [4 /*yield*/, branchApi.updateTeam(team === null || team === void 0 ? void 0 : team.id, data)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    if (!team)
        return null;
    return (React.createElement("div", null,
        React.createElement("div", { className: "admin-page-title" }, team.title + " (" + ((_a = team === null || team === void 0 ? void 0 : team.branch) === null || _a === void 0 ? void 0 : _a.title) + ")"),
        React.createElement("div", null,
            React.createElement("div", { className: "admin-form-block" },
                React.createElement(InputComponent, { name: "title", value: team.title, onChange: onChange, disabled: true, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })),
            React.createElement("div", { className: "admin-page-second-block" },
                React.createElement("div", { className: "admin-page-subtitle" }, "\u0418\u0433\u0440\u043E\u043A\u0438"),
                React.createElement("table", { className: "admin-table" },
                    React.createElement("tr", null,
                        React.createElement("th", null, "\u0418\u043C\u044F"),
                        React.createElement("th", null, "\u041F\u043E\u0437\u0438\u0446\u0438\u044F"),
                        React.createElement("th", null, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
                    team.players.map(function (player) { return (React.createElement("tr", { key: player.id },
                        React.createElement("td", null, "" + player.name),
                        React.createElement("td", null, player.position),
                        React.createElement("td", null,
                            React.createElement(Link, { to: "/admin/members/" + player.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); }))),
            React.createElement("div", { className: "admin-page-third-block" },
                React.createElement("div", { className: "admin-page-subtitle" }, "\u0422\u0440\u0435\u043D\u0435\u0440\u0441\u043A\u0438\u0439 \u0448\u0442\u0430\u0431"),
                React.createElement("table", { className: "admin-table" },
                    React.createElement("tr", null,
                        React.createElement("th", null, "\u0418\u043C\u044F"),
                        React.createElement("th", null, "\u041F\u043E\u0437\u0438\u0446\u0438\u044F"),
                        React.createElement("th", null, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
                    team.staff.map(function (player) { return (React.createElement("tr", { key: player.id },
                        React.createElement("td", null, "" + player.name),
                        React.createElement("td", null, player.position),
                        React.createElement("td", null,
                            React.createElement(Link, { to: "/admin/members/" + player.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); }))))));
};
