var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { showNotification } from '@utils/notification';
import { adminApi } from '@api';
import './styles/index.scss';
export var AdminTournamentTable = function (_a) {
    var id = _a.id;
    var _b = useState({}), data = _b[0], setData = _b[1];
    var getData = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, adminApi.getAdminTournamentTable(id)];
                case 1:
                    res = _a.sent();
                    setData(res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    showNotification('error', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!id)
            return;
        getData(id);
    }, [id]);
    var refresh = function () {
        getData(id);
    };
    if (!data.results)
        return null;
    return (React.createElement("div", { className: "admin-tournament-table" },
        React.createElement("div", null,
            "\u0418\u0442\u043E\u0433\u043E\u0432\u0430\u044F \u0442\u0430\u0431\u043B\u0438\u0446\u0430 (",
            React.createElement("span", { className: "admin-tournament-table-refresh", onClick: function () { return refresh(); } }, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"),
            ")"),
        React.createElement("br", null),
        React.createElement("table", { className: "admin-table" },
            React.createElement("tr", null,
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u041F\u043E\u0437\u0438\u0446\u0438\u044F"),
                React.createElement("th", null, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u0418\u0433\u0440\u044B"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u0412\u044B\u0438\u0433"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u041D\u0438\u0447"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u041F\u043E\u0440\u0430\u0436"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u0417\u0430\u0431"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u041F\u0440\u043E\u043F\u0443\u0449"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u0420\u0430\u0437\u043D"),
                React.createElement("th", { className: "admin-tournament-table-point" }, "\u041E\u0447\u043A\u0438"),
                React.createElement("th", null)),
            data.results.map(function (result, index) { return (React.createElement("tr", { key: result.title },
                React.createElement("td", { className: "admin-tournament-table-point" }, index + 1),
                React.createElement("td", null, result.teamTitle + " " + (result.teamPostfix || '')),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.matches),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.win),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.draw),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.lose),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.scored),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.conceded),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.difference),
                React.createElement("td", { className: "admin-tournament-table-point" }, result.points))); }))));
};
