// @ts-nocheck
import axios from 'axios';
export var getList = function () { return axios.get('/api/teams/list'); };
export var getById = function (id) { return axios.get("/api/teams/" + id); };
export var getTeamCalendar = function (id) { return axios.get("/api/teams/" + id + "/calendar"); };
export var getTeamResults = function (id) { return axios.get("/api/teams/" + id + "/results"); };
export var getTeamTables = function (id) { return axios.get("/api/teams/" + id + "/tables"); };
export var getTeamTournamentTables = function (id) { return axios.get("/api/teams/" + id + "/tournament-tables"); };
export var teamApi = {
    getList: getList,
    getById: getById,
    getTeamCalendar: getTeamCalendar,
    getTeamResults: getTeamResults,
    getTeamTables: getTeamTables,
    getTeamTournamentTables: getTeamTournamentTables,
};
