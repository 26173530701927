var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { InputComponent } from '@components/common';
import { adminApi } from '@api';
import './styles/index.scss';
import { showNotification } from '@utils/notification';
export var AdminStadium = function (props) {
    var stadiumData = props.stadiumData, onAddNewStadium = props.onAddNewStadium, onDeleteStadium = props.onDeleteStadium;
    var _a = useState(stadiumData || {}), stadium = _a[0], setStadium = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var onChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setStadium(__assign(__assign({}, stadium), (_a = {}, _a[name] = value, _a)));
    };
    var onSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    if (!stadium.title)
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, adminApi.createStadium(stadium)];
                case 1:
                    res = _a.sent();
                    showNotification('success', 'Стадион успешно добавлен');
                    setStadium({
                        title: '',
                        address: ''
                    });
                    onAddNewStadium(res.data);
                    return [3 /*break*/, 4];
                case 2:
                    err_1 = _a.sent();
                    showNotification('error', err_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [stadium]);
    var onUpdate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    if (!stadium.title)
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, adminApi.updateStadium(stadium.id, stadium)];
                case 1:
                    _a.sent();
                    showNotification('success', 'Стадион успешно обновлен');
                    return [3 /*break*/, 4];
                case 2:
                    err_2 = _a.sent();
                    showNotification('error', err_2);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [stadium]);
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, adminApi.deleteStadium(stadium.id)];
                case 1:
                    _a.sent();
                    onDeleteStadium(stadium.id);
                    showNotification('success', 'Стадион успешно удален');
                    return [3 /*break*/, 4];
                case 2:
                    err_3 = _a.sent();
                    showNotification('error', err_3);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("tr", null,
        React.createElement("td", null,
            React.createElement(InputComponent, { name: "title", className: "admin-stadium-component-title", value: stadium.title, onChange: onChange })),
        React.createElement("td", null,
            React.createElement(InputComponent, { name: "address", className: "admin-stadium-component-address", value: stadium.address, onChange: onChange })),
        React.createElement("td", { className: "admin-stadium-component-actions" },
            React.createElement("span", { onClick: stadium.id ? onUpdate : onSave }, stadium.id ? 'Сохр' : 'Добавить'),
            stadium.id
                && React.createElement(React.Fragment, null,
                    ' / ',
                    React.createElement("span", { onClick: onDelete }, "\u0423\u0434\u0430\u043B")))));
};
