// @ts-nocheck
export var DAYS = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
export var MONTHS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
export var modifyToBr = function (value) {
    try {
        var modified = value.replace(/(\r\n|\n)/g, '<br/>');
        return { __html: modified };
    }
    catch (e) {
        return { __html: value || '' };
    }
};
export var modifyDate = function (date, isInputFormat) {
    try {
        var d = new Date(date);
        var month = String(d.getMonth() + 1);
        var day = String(d.getDate());
        var year = String(d.getFullYear());
        if (month.length < 2)
            month = "0" + month;
        if (day.length < 2)
            day = "0" + day;
        if (isInputFormat) {
            return year + "-" + month + "-" + day;
        }
        return day + "." + month + "." + year;
    }
    catch (e) {
        return '';
    }
};
export var modifyDateCalendar = function (date) {
    try {
        var d = new Date(date);
        if (d.toString() === 'Invalid Date') {
            return '';
        }
        var monthNumber = String(d.getMonth() + 1);
        if (monthNumber.length < 2)
            monthNumber = "0" + monthNumber;
        var day = String(d.getDate());
        var year = String(d.getFullYear());
        if (day.length < 2)
            day = "0" + day;
        var month = void 0;
        switch (monthNumber) {
            case '01':
                month = 'Января';
                break;
            case '02':
                month = 'Февраля';
                break;
            case '03':
                month = 'Марта';
                break;
            case '04':
                month = 'Апреля';
                break;
            case '05':
                month = 'Мая';
                break;
            case '06':
                month = 'Июня';
                break;
            case '07':
                month = 'Июля';
                break;
            case '08':
                month = 'Августа';
                break;
            case '09':
                month = 'Сентября';
                break;
            case '10':
                month = 'Октября';
                break;
            case '11':
                month = 'Ноября';
                break;
            case '12':
                month = 'Декабря';
                break;
            default:
                month = monthNumber;
        }
        return day + " " + month + " " + year;
    }
    catch (e) {
        return '';
    }
};
export var getWeekday = function (date) {
    try {
        var d = new Date(date);
        if (d.toString() === 'Invalid Date') {
            return '';
        }
        var dayNumber = d.getDay();
        return DAYS[dayNumber];
    }
    catch (e) {
        return '';
    }
};
