var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { fetchProfile } from './accountThunk';
export var accountSlice = createSlice({
    name: 'account',
    initialState: {
        isAuth: false,
        isLoaded: false
    },
    reducers: {
        setAccount: function (state, action) { return (__assign(__assign(__assign({}, state), action.payload), { isAuth: true })); },
        setAccountLoaded: function (state, action) {
            state.isLoaded = true;
        }
    },
    extraReducers: function (builder) {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchProfile.fulfilled, function (state, action) { return (__assign(__assign(__assign({}, state), action.payload), { isAuth: true, isLoaded: true })); });
        builder.addCase(fetchProfile.rejected, function (state, action) {
            // Add user to the state array
            state.isAuth = false;
            state.isLoaded = true;
        });
    },
});
// First, create the thunk
export var setAccount = (_a = accountSlice.actions, _a.setAccount), setAccountLoaded = _a.setAccountLoaded;
export default accountSlice.reducer;
