// @ts-nocheck
import React, { useState, useEffect } from 'react';
import './styles/index.scss';
var LIMIT = 18;
export var Pagination = function (_a) {
    var _b = _a.total, total = _b === void 0 ? 0 : _b, onChangedPage = _a.onChangedPage;
    var _c = useState([]), pages = _c[0], setPages = _c[1];
    var _d = useState(0), activePage = _d[0], setActivePage = _d[1];
    useEffect(function () {
        var totalPages = Math.ceil(total / LIMIT);
        var arr = Array.from(Array(totalPages).keys());
        setPages(arr);
    }, [total]);
    useEffect(function () {
        onChangedPage({ skip: activePage * LIMIT, limit: LIMIT });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activePage]);
    return (React.createElement("div", { className: "pagination" }, pages.map(function (page) { return (React.createElement("button", { className: "pagination-button " + (activePage === page && 'pagination-button-active'), key: page, type: "button", onClick: function () { return setActivePage(page); } }, page + 1)); })));
};
