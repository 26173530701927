// @ts-nocheck
import React from 'react';
import { ButtonComponent } from '@components/common';
import './styles/index.scss';
export var FileUploadComponent = function (_a) {
    var onFileChange = _a.onFileChange;
    var hiddenFileInput = React.useRef(null);
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var handleChange = function (event) {
        var fileUploaded = event.target.files[0];
        onFileChange(fileUploaded);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonComponent, { onClick: handleClick, variant: "secondary" }, "\u041D\u043E\u0432\u043E\u0435 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0435"),
        React.createElement("input", { type: "file", accept: "image/*", ref: hiddenFileInput, onChange: handleChange, style: { display: 'none' } })));
};
