// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPostList } from '@store/post';
import { ButtonComponent, Pagination } from '@components/common';
import { modifyDate } from '@utils/modified';
var LIMIT = 18;
export var AdminPostListPage = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.post; }), list = _a.list, total = _a.total, loading = _a.loading;
    useEffect(function () {
        dispatch(fetchPostList({ skip: 0, limit: LIMIT }));
    }, []);
    var handleFetch = function (param) {
        dispatch(fetchPostList(param));
    };
    // if (loading) return <Loader/>;
    return (React.createElement("div", null,
        React.createElement("div", { className: "admin-page-title" },
            "\u041D\u043E\u0432\u043E\u0441\u0442\u0438",
            React.createElement(Link, { to: "/admin/post-create" },
                React.createElement(ButtonComponent, { size: "small" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))),
        React.createElement("table", { className: "admin-table" },
            React.createElement("tr", null,
                React.createElement("th", null, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A"),
                React.createElement("th", null, "\u0414\u0430\u0442\u0430 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u044F"),
                React.createElement("th", null, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
            list.map(function (post) { return (React.createElement("tr", { key: post.id },
                React.createElement("td", null, post.title),
                React.createElement("td", null, modifyDate(post.created)),
                React.createElement("td", null,
                    React.createElement(Link, { to: "/admin/posts/" + post.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); })),
        React.createElement(Pagination, { total: total, onChangedPage: handleFetch })));
};
