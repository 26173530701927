// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export var AdminBranchListPage = function () {
    var branches = useSelector(function (state) { return state.admin; }).branches;
    return (React.createElement("div", null,
        React.createElement("div", { className: "admin-page-title" }, "\u0424\u0438\u043B\u0438\u0430\u043B\u044B"),
        React.createElement("table", { className: "admin-table" },
            React.createElement("tr", null,
                React.createElement("th", null, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A"),
                React.createElement("th", null, "\u0414\u0430\u0442\u0430 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u044F"),
                React.createElement("th", { className: "admin-table-actions" }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
            branches.map(function (branch) { return (React.createElement("tr", { key: branch.id },
                React.createElement("td", null, branch.title),
                React.createElement("td", null, branch.description),
                React.createElement("td", null,
                    React.createElement(Link, { to: "/admin/branches/" + branch.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); }))));
};
