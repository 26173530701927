// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export var AdminTeamListPage = function () {
    var _a = useSelector(function (state) { return state.admin; }), branches = _a.branches, teams = _a.teams;
    var getBranchName = function (branchId) {
        var branch = branches.find(function (item) { return item.id === branchId; });
        return branch === null || branch === void 0 ? void 0 : branch.title;
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "admin-page-title" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u044B"),
        React.createElement("table", { className: "admin-table" },
            React.createElement("tr", null,
                React.createElement("th", null, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435"),
                React.createElement("th", null, "\u0424\u0438\u043B\u0438\u0430\u043B"),
                React.createElement("th", { className: "admin-table-actions" }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")),
            teams.map(function (team) { return (React.createElement("tr", { key: team.id },
                React.createElement("td", null, team.title),
                React.createElement("td", null, getBranchName(team.branch)),
                React.createElement("td", null,
                    React.createElement(Link, { to: "/admin/teams/" + team.id }, "\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440")))); }))));
};
