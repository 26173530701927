// @ts-nocheck
import axios from 'axios';
// branch
export var getList = function () { return axios.get('/api/branch/list'); };
export var getById = function (id) { return axios.get("/api/branch/" + id); };
export var getByType = function (type) { return axios.get("/api/branch/type/" + type); };
export var update = function (id, data) { return axios.put("/api/branch/" + id, data); };
export var branchApi = {
    getList: getList,
    getById: getById,
    getByType: getByType,
    update: update
};
