// @ts-nocheck
import React from 'react';
import './styles/index.scss';
export var InputComponent = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'text' : _b, name = _a.name, value = _a.value, onChange = _a.onChange, label = _a.label, className = _a.className, placeholder = _a.placeholder;
    var inputClassName = "input-component " + (className || '');
    return (React.createElement("div", { className: inputClassName },
        React.createElement("label", { htmlFor: "" }, label),
        React.createElement("input", { name: name, value: value, onChange: onChange, placeholder: placeholder, type: type })));
};
