// @ts-nocheck
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@components/common';
import { AdminDashboardComponent, AdminSidebarComponent } from '@components/admin';
import { fetchMainInfo } from '@store/admin';
import { AdminPostListPage } from './AdminPostListPage';
import { AdminBranchListPage } from './AdminBranchListPage';
import { AdminTeamListPage } from './AdminTeamListPage';
import { AdminBranchPage } from './AdminBranchPage';
import { AdminTeamPage } from './AdminTeamPage';
import { AdminPlayerListPage } from './AdminPlayerListPage';
import { AdminStaffListPage } from './AdminStaffListPage';
import { AdminPostCreatePage } from './AdminPostCreatePage';
import { AdminPostEditPage } from './AdminPostEditPage';
import { AdminMemberCreatePage } from './AdminMemberCreatePage';
import { AdminMemberEditPage } from './AdminMemberEditPage';
import { AdminApplicationListPage } from './AdminApplicationListPage';
import { AdminTournamentListPage } from './AdminTournamentListPage';
import { AdminTournamentCreatePage } from './AdminTournamentCreatePage';
import { AdminTournamentPage } from './AdminTournamentPage';
import { AdminTournamentTeamsPage } from './AdminTournamentTeamsPage';
import { AdminTournamentCreateTeamPage } from './AdminTournamentCreateTeamPage';
import { AdminTournamentEditTeamPage } from './AdminTournamentEditTeamPage';
import { AdminTournamentCustomMatchesPage } from './AdminTournamentCustomMatchesPage';
import { AdminStadiumsPage } from './AdminStadiumsPage';
import './styles/index.scss';
export var AdminPage = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.admin; }), branches = _a.branches, teams = _a.teams;
    useEffect(function () {
        dispatch(fetchMainInfo());
    }, []);
    if (!(branches === null || branches === void 0 ? void 0 : branches.length) || !(teams === null || teams === void 0 ? void 0 : teams.length))
        return React.createElement(Loader, null);
    return (React.createElement("div", { className: "admin-page" },
        React.createElement("div", { className: "admin-page-sidebar" },
            React.createElement(AdminSidebarComponent, null)),
        React.createElement("div", { className: "admin-page-content" },
            React.createElement("div", { className: "admin-page-content-box" },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/admin", component: AdminDashboardComponent }),
                    React.createElement(Route, { path: "/admin/post-list", component: AdminPostListPage }),
                    React.createElement(Route, { path: "/admin/post-create", component: AdminPostCreatePage }),
                    React.createElement(Route, { path: "/admin/posts/:id", component: AdminPostEditPage }),
                    React.createElement(Route, { path: "/admin/branch-list", component: AdminBranchListPage }),
                    React.createElement(Route, { path: "/admin/branches/:id", component: AdminBranchPage }),
                    React.createElement(Route, { path: "/admin/team-list", component: AdminTeamListPage }),
                    React.createElement(Route, { path: "/admin/teams/:id", component: AdminTeamPage }),
                    React.createElement(Route, { path: "/admin/player-list", component: AdminPlayerListPage }),
                    React.createElement(Route, { path: "/admin/staff-list", component: AdminStaffListPage }),
                    React.createElement(Route, { path: "/admin/member-create", component: AdminMemberCreatePage }),
                    React.createElement(Route, { path: "/admin/members/:id", component: AdminMemberEditPage }),
                    React.createElement(Route, { path: "/admin/application-list", component: AdminApplicationListPage }),
                    React.createElement(Route, { path: "/admin/tournaments-list", component: AdminTournamentListPage }),
                    React.createElement(Route, { path: "/admin/tournament-create", component: AdminTournamentCreatePage }),
                    React.createElement(Route, { path: "/admin/tournaments/:id", component: AdminTournamentPage }),
                    React.createElement(Route, { path: "/admin/tournament/teams-list", component: AdminTournamentTeamsPage }),
                    React.createElement(Route, { path: "/admin/tournament/create-team", component: AdminTournamentCreateTeamPage }),
                    React.createElement(Route, { path: "/admin/tournament/team/:id", component: AdminTournamentEditTeamPage }),
                    React.createElement(Route, { path: "/admin/tournament/custom-matches", component: AdminTournamentCustomMatchesPage }),
                    React.createElement(Route, { path: "/admin/stadiums", component: AdminStadiumsPage }))))));
};
