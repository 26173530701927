var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// @ts-nocheck
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './styles/index.scss';
export var ModalComponent = function (props) {
    var closeModal = props.closeModal, children = props.children, other = __rest(props, ["closeModal", "children"]);
    useEffect(function () {
        Modal.setAppElement('#modal');
    }, []);
    return (React.createElement(Modal, __assign({}, other, { onRequestClose: closeModal }),
        React.createElement("div", { className: "modal-component-close", onClick: closeModal }),
        React.createElement("div", { className: "modal-component" },
            React.createElement("div", { className: "modal-component-body" }, children))));
};
