var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { PATH_TO_FILE } from '@utils/constants';
import { ButtonComponent, InputComponent, ModalComponent } from '@components/common';
import { AdminGallery } from '@components/admin';
import './styles/index.scss';
export var AdminTournamentTeamComponent = function (props) {
    var teamData = props.teamData, onEvent = props.onEvent, onDelete = props.onDelete, loading = props.loading;
    var _a = useState({}), team = _a[0], setTeam = _a[1];
    var _b = useState(''), teamImageUrl = _b[0], setTeamImageUrl = _b[1];
    var _c = useState(false), isOpenGallery = _c[0], setIsOpenGallery = _c[1];
    useEffect(function () {
        if (!(teamData === null || teamData === void 0 ? void 0 : teamData.id))
            return;
        setTeam(teamData);
        setTeamImageUrl(teamData === null || teamData === void 0 ? void 0 : teamData.imageUrl);
    }, [teamData]);
    var handleEvent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            obj = __assign({}, team);
            obj.imageUrl = teamImageUrl;
            onEvent(obj);
            return [2 /*return*/];
        });
    }); };
    var onChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        setTeam(__assign(__assign({}, team), (_a = {}, _a[name] = value, _a)));
    };
    var closeModal = function () {
        setIsOpenGallery(false);
    };
    var onChooseImage = function (url) {
        setTeamImageUrl(url);
        closeModal();
    };
    var disabled = !team.title;
    return (React.createElement("div", { className: "admin-tournament-team-component" },
        React.createElement("div", { className: "admin-post-component-content" },
            React.createElement("div", { className: "admin-post-component-left" },
                React.createElement("div", { className: "admin-post-component-image " + (teamImageUrl && 'has-image') },
                    React.createElement("img", { src: PATH_TO_FILE + "/" + teamImageUrl, alt: "" })),
                React.createElement(ButtonComponent, { variant: "outline", onClick: function () { return setIsOpenGallery(true); } }, teamImageUrl ? 'Выбрать другое' : 'Добавить изображение')),
            React.createElement("div", { className: "admin-post-component-right" },
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(InputComponent, { name: "title", value: team.title, onChange: onChange, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })),
                React.createElement("div", { className: "admin-post-component-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button", onClick: handleEvent, disabled: disabled, loading: loading }, team.id ? 'Редактировать' : 'Создать')),
                onDelete && (React.createElement("div", { className: "admin-post-component-handler admin-post-component-delete-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button admin-post-component-delete-handler-button", onClick: onDelete, disabled: disabled, loading: loading }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))))),
        React.createElement(ModalComponent, { isOpen: isOpenGallery, closeModal: closeModal },
            React.createElement(AdminGallery, { onChooseImage: onChooseImage }))));
};
