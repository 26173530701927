// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './account/accountSlice';
import postReducer from './post/postSlice';
import branchReducer from './branch/branchSlice';
import adminReducer from './admin/adminSlice';
export default configureStore({
    reducer: {
        account: accountReducer,
        post: postReducer,
        branch: branchReducer,
        admin: adminReducer
    }
});
