import { createSlice } from '@reduxjs/toolkit';
import { fetchPostList } from './postThunk';
export var postSlice = createSlice({
    name: 'post',
    initialState: {
        loading: false,
        list: [],
        total: 0
    },
    reducers: {
        setPostList: function (state, action) {
            state.list = action.payload.items;
            state.total = action.payload.total;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(fetchPostList.pending, function (state, action) {
            state.loading = true;
        });
        builder.addCase(fetchPostList.fulfilled, function (state, action) {
            // Add user to the state array
            state.list = action.payload.items;
            state.total = action.payload.total;
            state.loading = false;
        });
    },
});
// First, create the thunk
export var setPostList = postSlice.actions.setPostList;
export default postSlice.reducer;
