// @ts-nocheck
import axios from 'axios';
// branch
export var getById = function (id) { return axios.get("/api/admin/member/" + id); };
export var update = function (id, data) { return axios.put("/api/admin/member/" + id, data); };
export var getStaff = function () { return axios.get('/api/members/list-staff'); };
export var memberApi = {
    getById: getById,
    update: update,
    getStaff: getStaff
};
