var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_TO_FILE } from '@utils/constants';
import { ButtonComponent, InputComponent, ModalComponent, SelectComponent, TextareaComponent } from '@components/common';
import { AdminGallery } from '@components/admin';
import { branchApi, teamApi } from '@api';
import { PLAYERS_POSITIONS, STAFF_POSITIONS } from '@common';
import './styles/index.scss';
export var AdminMemberComponent = function (props) {
    var memberData = props.memberData, onEvent = props.onEvent, onDelete = props.onDelete, loading = props.loading;
    var search = useLocation().search;
    var _a = useState(memberData || { teams: [] }), member = _a[0], setMember = _a[1];
    var _b = useState(false), isOpenGallery = _b[0], setIsOpenGallery = _b[1];
    var _c = useState(''), memberImageUrl = _c[0], setMemberImageUrl = _c[1];
    var _d = useState([]), branches = _d[0], setBranches = _d[1];
    var _e = useState([]), teams = _e[0], setTeams = _e[1];
    var isStaff = new URLSearchParams(search).get('is-staff') === 'true';
    var positions = isStaff ? STAFF_POSITIONS : PLAYERS_POSITIONS;
    var teamItems = useMemo(function () {
        var t = teams.filter(function (item) { return item.branch === member.branch; });
        return t.map(function (item) { return ({
            title: item.title,
            value: item.id
        }); });
    }, [teams, member.branch]);
    useEffect(function () {
        var getInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res1, res2, branchItems;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, branchApi.getList()];
                    case 1:
                        res1 = _a.sent();
                        return [4 /*yield*/, teamApi.getList()];
                    case 2:
                        res2 = _a.sent();
                        branchItems = res1.data.map(function (item) { return ({
                            title: item.title,
                            value: item.id
                        }); });
                        setBranches(branchItems);
                        setTeams(res2.data);
                        return [2 /*return*/];
                }
            });
        }); };
        getInfo();
    }, []);
    useEffect(function () {
        if (!(memberData === null || memberData === void 0 ? void 0 : memberData.id))
            return;
        setMemberImageUrl(memberData === null || memberData === void 0 ? void 0 : memberData.imageUrl);
    }, [memberData]);
    var handleEvent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            obj = __assign({}, member);
            obj.imageUrl = memberImageUrl;
            if (isStaff) {
                obj.role = 'staff';
            }
            onEvent(obj, isStaff);
            return [2 /*return*/];
        });
    }); };
    var onChange = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        setMember(__assign(__assign({}, member), (_a = {}, _a[name] = value, _a)));
    };
    var onChangeTeams = function (e) {
        var value = e.target.value;
        var memberTeams = __spreadArrays(member.teams);
        var index = memberTeams.indexOf(value);
        if (index === -1) {
            setMember(__assign(__assign({}, member), { teams: __spreadArrays(memberTeams, [value]) }));
        }
        else {
            memberTeams.splice(index, 1);
            setMember(__assign(__assign({}, member), { teams: memberTeams }));
        }
    };
    var closeModal = function () {
        setIsOpenGallery(false);
    };
    var onChooseImage = function (url) {
        setMemberImageUrl(url);
        closeModal();
    };
    var disabled = !member.name || !member.branch;
    return (React.createElement("div", { className: "admin-member-component" },
        React.createElement("div", { className: "admin-post-component-content" },
            React.createElement("div", { className: "admin-post-component-left" },
                React.createElement("div", { className: "admin-post-component-image " + (memberImageUrl && 'has-image') },
                    React.createElement("img", { src: PATH_TO_FILE + "/" + memberImageUrl, alt: "" })),
                React.createElement(ButtonComponent, { variant: "outline", onClick: function () { return setIsOpenGallery(true); } }, memberImageUrl ? 'Выбрать другое' : 'Добавить изображение')),
            React.createElement("div", { className: "admin-post-component-right" },
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(InputComponent, { name: "name", value: member.name, onChange: onChange, label: "\u0418\u043C\u044F" })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(TextareaComponent, { name: "description", value: member.description, onChange: onChange, label: "\u041A\u0440\u0430\u0442\u043A\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F (\u043D\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E)" })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(InputComponent, { name: "birthday", value: member.birthday, onChange: onChange, label: "\u0414\u0435\u043D\u044C \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F" })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(SelectComponent, { name: "position", label: "\u041F\u043E\u0437\u0438\u0446\u0438\u044F", value: member.position, onChange: onChange, options: positions })),
                React.createElement("div", { className: "admin-form-block" },
                    React.createElement(SelectComponent, { name: "branch", label: "\u0424\u0438\u043B\u0438\u0430\u043B", value: member.branch, onChange: onChange, options: branches })),
                isStaff ? (React.createElement("div", { className: "admin-form-block" },
                    React.createElement("div", null, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430"),
                    teamItems.map(function (item) { return (React.createElement("span", { key: item.value },
                        React.createElement("input", { type: "checkbox", value: item.value, checked: member.teams.includes(item.value), onChange: onChangeTeams }),
                        React.createElement("span", { className: "admin-member-component-checkbox-title" }, item.title))); }))) : (React.createElement("div", { className: "admin-form-block" },
                    React.createElement(SelectComponent, { name: "team", label: "\u041A\u043E\u043C\u0430\u043D\u0434\u0430", value: member.team, onChange: onChange, options: teamItems }))),
                React.createElement("div", { className: "admin-post-component-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button", onClick: handleEvent, disabled: disabled, loading: loading }, member.id ? 'Редактировать' : 'Создать')),
                onDelete && (React.createElement("div", { className: "admin-post-component-handler admin-post-component-delete-handler" },
                    React.createElement(ButtonComponent, { className: "admin-post-component-handler-button admin-post-component-delete-handler-button", onClick: onDelete, disabled: disabled, loading: loading }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))))),
        React.createElement(ModalComponent, { isOpen: isOpenGallery, closeModal: closeModal },
            React.createElement(AdminGallery, { onChooseImage: onChooseImage }))));
};
