import { createSlice } from '@reduxjs/toolkit';
import { fetchMainInfo } from './adminThunk';
export var adminSlice = createSlice({
    name: 'admin',
    initialState: {
        loading: false,
        branches: [],
        teams: []
    },
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(fetchMainInfo.pending, function (state, action) {
            state.loading = true;
        });
        builder.addCase(fetchMainInfo.fulfilled, function (state, action) {
            state.branches = action.payload.branches;
            state.teams = action.payload.teams;
            state.loading = false;
        });
    },
});
// export const {} = branchSlice.actions;
export default adminSlice.reducer;
