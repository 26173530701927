var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// @ts-nocheck
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { AdminTournamentMatchComponent } from '@components/admin';
import { showNotification } from '@utils/notification';
import { adminApi } from '@api';
import './styles/index.scss';
export var AdminTournamentMatchesComponent = function (props) {
    var tournament = props.tournament;
    var _a = useState([]), matches = _a[0], setMatches = _a[1];
    var _b = useState([]), customMatches = _b[0], setCustomMatches = _b[1];
    var _c = useState([]), stadiums = _c[0], setStadiums = _c[1];
    var _d = useState([]), roundsArr = _d[0], setRoundsArr = _d[1];
    var _e = useState([]), showedRounds = _e[0], setShowedRounds = _e[1];
    var teams = (tournament === null || tournament === void 0 ? void 0 : tournament.teams) || [];
    var selectTeams = teams.map(function (team) { return ({
        title: team.title + " " + (team.postfix || ''),
        value: team.id
    }); });
    var selectStadiums = stadiums.map(function (stadium) { return ({
        title: stadium.title,
        value: stadium.id
    }); });
    useEffect(function () {
        if (!tournament.rounds)
            return;
        var arr = Array.from({ length: Number(tournament.rounds) }, function (_, index) { return index + 1; });
        setRoundsArr(arr);
    }, [tournament.rounds]);
    useEffect(function () {
        var custom = matches.filter(function (item) { return !!item.isCustomMatch; });
        setCustomMatches(custom);
    }, [matches]);
    var getMatches = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, adminApi.getTournamentMatches(id)];
                case 1:
                    res = _a.sent();
                    setMatches(res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    showNotification('error', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getStadiums = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, adminApi.getStadiums()];
                case 1:
                    res = _a.sent();
                    setStadiums(res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    showNotification('error', e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!tournament.id)
            return;
        getMatches(tournament.id);
        getStadiums();
    }, [tournament.id]);
    var matchesByRound = useMemo(function () {
        var obj = {};
        matches.forEach(function (match) {
            var current = obj[match.round];
            if (Array.isArray(current)) {
                obj[match.round] = __spreadArrays(current, [match]);
            }
            else {
                obj[match.round] = [match];
            }
        });
        return obj;
    }, [matches]);
    var onAddNewMatch = function (newM) {
        setMatches(function (prevState) { return (__spreadArrays(prevState, [
            newM
        ])); });
    };
    var toggleRound = useCallback(function (r) {
        if (showedRounds.includes(r)) {
            var filtered = showedRounds.filter(function (i) { return i !== r; });
            setShowedRounds(filtered);
        }
        else {
            setShowedRounds(__spreadArrays(showedRounds, [r]));
        }
    }, [showedRounds]);
    return (React.createElement("div", { className: "admin-tournament-matches-component" },
        !tournament.isCup && (React.createElement("div", null,
            React.createElement("div", null, "\u041C\u0430\u0442\u0447\u0438"),
            React.createElement("br", null),
            roundsArr.map(function (round) {
                var _a;
                return (React.createElement("div", { className: "admin-tournament-matches-component-round-box" },
                    React.createElement("div", { className: "admin-tournament-matches-component-round-box-title", onClick: function () { return toggleRound(round); } },
                        React.createElement("span", null, "\u0422\u0443\u0440 " + round),
                        React.createElement("span", { className: "admin-tournament-matches-component-round-box-title-icon " + (showedRounds.includes(round) && 'opened') })),
                    React.createElement("div", { className: "admin-tournament-matches-component-table-wrap " + (showedRounds.includes(round) && 'showed') },
                        React.createElement("table", { className: "admin-table" },
                            React.createElement("tr", null,
                                React.createElement("th", null, "\u0421\u043A\u0440\u044B\u0442\u044C"),
                                React.createElement("th", null, "\u0414\u0430\u0442\u0430"),
                                React.createElement("th", null, "\u0412\u0440\u0435\u043C\u044F"),
                                React.createElement("th", null, "\u0421\u0442\u0430\u0434\u0438\u043E\u043D"),
                                React.createElement("th", null, "\u0414\u043E\u043C"),
                                React.createElement("th", null, "\u0413\u043E\u0441\u0442\u0438"),
                                React.createElement("th", null, "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442"),
                                React.createElement("th", null)),
                            React.createElement(AdminTournamentMatchComponent, { teams: selectTeams, round: round, tournament: tournament.id, stadiums: selectStadiums, withTitle: false, onAddNewMatch: onAddNewMatch }), (_a = matchesByRound === null || matchesByRound === void 0 ? void 0 : matchesByRound[round]) === null || _a === void 0 ? void 0 :
                            _a.map(function (match) { return (React.createElement(AdminTournamentMatchComponent, { matchData: match, teams: selectTeams, round: round, tournament: tournament.id, withTitle: false, stadiums: selectStadiums })); })))));
            }))),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("div", null,
            "\u041C\u0430\u0442\u0447\u0438 \u043F\u043B\u0435\u0439 \u043E\u0444\u0444",
            React.createElement("br", null),
            React.createElement("br", null)),
        React.createElement("div", { className: "admin-table-wrapper" },
            React.createElement("table", { className: "admin-table" },
                React.createElement("tr", null,
                    React.createElement("th", null, "\u0421\u043A\u0440\u044B\u0442\u044C"),
                    React.createElement("th", null, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435"),
                    React.createElement("th", null, "\u0414\u0430\u0442\u0430"),
                    React.createElement("th", null, "\u0412\u0440\u0435\u043C\u044F"),
                    React.createElement("th", null, "\u0421\u0442\u0430\u0434\u0438\u043E\u043D"),
                    React.createElement("th", null, "\u0414\u043E\u043C"),
                    React.createElement("th", null, "\u0413\u043E\u0441\u0442\u0438"),
                    React.createElement("th", null, "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442"),
                    React.createElement("th", null, "\u041F\u0440\u0438\u043C\u0435\u0447\u0430\u043D\u0438\u0435"),
                    React.createElement("th", null)),
                React.createElement(AdminTournamentMatchComponent, { teams: selectTeams, tournament: tournament.id, stadiums: selectStadiums, withTitle: true, isCustomMatch: true, onAddNewMatch: onAddNewMatch }),
                customMatches.map(function (match) { return (React.createElement(AdminTournamentMatchComponent, { matchData: match, teams: selectTeams, tournament: tournament.id, stadiums: selectStadiums, withTitle: true, isCustomMatch: true })); })))));
};
