// @ts-nocheck
import axios from 'axios';
export var getMainInfo = function () { return axios.get('/api/admin/main-info'); };
export var createPost = function (data) {
    return axios.post('/api/admin/create-post', data);
};
export var uploadImage = function (data) { return axios.post('/api/admin/upload-image', data); };
export var getGallery = function () { return axios.get('/api/admin/gallery'); };
export var getPlayerList = function () { return axios.get('/api/admin/player/list'); };
export var getStaffList = function () { return axios.get('/api/admin/staff/list'); };
export var createMember = function (data) {
    return axios.post('/api/admin/create-member', data);
};
export var getMemberById = function (id) { return axios.get("/api/admin/member/" + id); };
export var updateMember = function (id, data) {
    return axios.put("/api/admin/member/" + id, data);
};
export var deleteMember = function (id) { return axios.delete("/api/admin/member/" + id); };
export var getApplicationList = function () { return axios.get('/api/admin/application/list'); };
// Tournaments
export var createTournament = function (data) {
    return axios.post('/api/admin/tournament/create', data);
};
export var getTournamentsList = function () { return axios.get('/api/admin/tournament/list'); };
export var getTournamentById = function (id) {
    return axios.get("/api/admin/tournament/" + id);
};
export var updateTournament = function (id, data) {
    return axios.put("/api/admin/tournament/" + id, data);
};
export var deleteTournament = function (id) {
    return axios.delete("/api/admin/tournament/" + id);
};
export var getAdminTournamentTable = function (id) {
    return axios.get("/api/admin/tournaments/" + id + "/table");
};
// Tournaments Teams
export var getTournamentTeams = function () {
    return axios.get('/api/admin/tournament/teams-list');
};
export var getTournamentAndOurTeams = function () {
    return axios.get('/api/admin/tournament/teams-our-list');
};
export var createTournamentTeam = function (data) {
    return axios.post('/api/admin/tournament/create-team', data);
};
export var getTournamentTeamById = function (id) {
    return axios.get("/api/admin/tournament/teams/" + id);
};
export var updateTournamentTeam = function (id, data) {
    return axios.put("/api/admin/tournament/teams/" + id, data);
};
export var deleteTournamentTeam = function (id) {
    return axios.delete("/api/admin/tournament/teams/" + id);
};
// Tournaments Matches
export var getTournamentMatches = function (id) {
    return axios.get("/api/admin/tournament/" + id + "/matches");
};
export var createTournamentMatch = function (data) {
    return axios.post('/api/admin/tournament/create-match', data);
};
export var getTournamentMatchById = function (id) {
    return axios.get("/api/admin/tournament/matches/" + id);
};
export var updateTournamentMatch = function (id, data) {
    return axios.put("/api/admin/tournament/matches/" + id, data);
};
export var deleteTournamentMatch = function (id) {
    return axios.delete("/api/admin/tournament/matches/" + id);
};
// Tournaments Stadiums
export var getStadiums = function () { return axios.get('/api/admin/stadiums/list'); };
export var createStadium = function (data) {
    return axios.post('/api/admin/create-stadium', data);
};
export var updateStadium = function (id, data) {
    return axios.put("/api/admin/stadiums/" + id, data);
};
export var deleteStadium = function (id) { return axios.delete("/api/admin/stadiums/" + id); };
export var getCustomMatchesList = function () { return axios.get('/api/admin/custom-matches'); };
export var adminApi = {
    getMainInfo: getMainInfo,
    createPost: createPost,
    uploadImage: uploadImage,
    getGallery: getGallery,
    getPlayerList: getPlayerList,
    getStaffList: getStaffList,
    createMember: createMember,
    getMemberById: getMemberById,
    updateMember: updateMember,
    deleteMember: deleteMember,
    getApplicationList: getApplicationList,
    createTournament: createTournament,
    getTournamentsList: getTournamentsList,
    getTournamentById: getTournamentById,
    updateTournament: updateTournament,
    deleteTournament: deleteTournament,
    getTournamentTeams: getTournamentTeams,
    getTournamentAndOurTeams: getTournamentAndOurTeams,
    createTournamentTeam: createTournamentTeam,
    getTournamentTeamById: getTournamentTeamById,
    updateTournamentTeam: updateTournamentTeam,
    deleteTournamentTeam: deleteTournamentTeam,
    getTournamentMatches: getTournamentMatches,
    createTournamentMatch: createTournamentMatch,
    getTournamentMatchById: getTournamentMatchById,
    updateTournamentMatch: updateTournamentMatch,
    deleteTournamentMatch: deleteTournamentMatch,
    getAdminTournamentTable: getAdminTournamentTable,
    getCustomMatchesList: getCustomMatchesList,
    getStadiums: getStadiums,
    createStadium: createStadium,
    updateStadium: updateStadium,
    deleteStadium: deleteStadium,
};
