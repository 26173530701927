// @ts-nocheck
// const emailRegexp = /.{2,}@(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gim
// const emailRegexp = /.{2,}@.{2,}/
var emailRegexp = /^\S+@\S+\.\S+$/;
export var emailValidator = function (value) {
    if (value.length === 0)
        return 'Введите email';
    if (!emailRegexp.test(value))
        return 'Введите корректный email';
    return null;
};
var passwordRegexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
export var passwordValidator = function (value) {
    if (value.length === 0)
        return 'Please, enter password';
    if (!passwordRegexp.test(value))
        return 'Your password should include at least uppercase, lowercase, number and special symbol';
    return null;
};
